<template>
  <table class="printer-ticket" id="#print">
    <thead>
      <tr>
        <th class="title" colspan="3">Regalli Semijias <br />CNPJ:35.306.627/0001-50</th>
      </tr>

      <tr>
        <th colspan="3">
          Shopping Villa Real <br />
          Av. Mal. Artur da Costa e Silva, 1380
          <br />Box 3 - Jardim Gloria, CEP: 13487-230 <br />Limeira - SP, WhatsApp :(19) 99661-9942
        </th>
      </tr>

      <tr>
        <th colspan="3">{{ dateHour }}</th>
      </tr>
      <tr v-if="formData.client_id">
        <th colspan="3" class="ttu">
          <b>Cliente: {{ formData.client_name }}</b>
        </th>
      </tr>
      <tr>
        <th class="ttu" colspan="3">
          <b>Pedido Nº {{ formData.code }}</b
          ><br />
          <b>Cupom não fiscal</b>
        </th>
      </tr>
    </thead>

    <tbody>
      <tr class="top">
        <td colspan="3"></td>
      </tr>
    </tbody>

    <tbody v-for="(data, index) in prodcut" :key="index">
      <tr>
        <td colspan="3">{{ data.name_product }}</td>
      </tr>
      <tr>
        <td>R$ {{ data.price_un }}</td>
        <td>{{ data.amount }}</td>
        <td>R$ {{ data.price_total }}</td>
      </tr>
    </tbody>

    <br />

    <tfoot>
      <tr class="sup ttu p--0">
        <td colspan="3"></td>
      </tr>
      <tr class="ttu">
        <td colspan="2">CONSULTORA</td>
        <td align="right">{{ formData.nameUser }}</td>
      </tr>
      <tr class="ttu">
        <td colspan="2">Caixa</td>
        <td align="right">{{ formData.boxCode }}</td>
      </tr>
      <tr class="ttu">
        <td colspan="2">Aberto</td>
        <td align="right">{{ formData.openDateHour }}</td>
      </tr>
      <tr class="ttu">
        <td colspan="2">Fechado</td>
        <td align="right">{{ formData.openDateHour }}</td>
      </tr>

      <tr class="sup ttu p--0">
        <td colspan="3">
          <b>Totais</b>
        </td>
      </tr>
      <tr class="ttu">
        <td colspan="2">Acréscimo</td>
        <td align="right">R$ {{ formData.boxTotal.price_addition }}</td>
      </tr>
      <tr class="ttu">
        <td colspan="2">Total venda</td>
        <td align="right">R$ {{ formData.boxTotal.price_total }}</td>
      </tr>


      <tr class="ttu" v-if="formData.boxTotal.type_discount ==1">
        <td colspan="2">Desconto%</td>
        <td align="right">{{ formData.boxTotal.discount }}%</td>
      </tr>

      <tr class="ttu" v-if="formData.boxTotal.type_discount ==2">
        <td colspan="2">Desconto R$</td>
        <td align="right">R$ {{ formData.boxTotal.discount }}</td>
      </tr>

      <tr class="ttu">
        <td colspan="2">Total Venda Desconto</td>
        <td align="right">R$ {{ formData.boxTotal.price_total_discount }}</td>
      </tr>
      <tr class="ttu">
        <td colspan="2">Valor Pago</td>
        <td align="right">R$ {{ formData.boxTotal.price_paid_out }}</td>
      </tr>

      <tr class="ttu boldText">
        <td colspan="2">Troco</td>
        <td align="right">R$ {{ formData.boxTotal.price_change }}</td>
      </tr>

      <tr class="sup ttu p--0">
        <td colspan="3">
          <b>Pagamento </b>
        </td>
      </tr>
      <tr class="ttu" v-for="(data, index) in payment" :key="index">
        <td v-if="data.type == 1" colspan="2">Dinherio</td>
        <td v-if="data.type == 2" colspan="2">PIX</td>
        <td v-if="data.type == 3" colspan="2">Cartão de Crédito</td>
        <td v-if="data.type == 4" colspan="2">Cartão de Débito</td>
        <td align="right">R$ {{ data.price }}</td>
      </tr>
    </tfoot>
  </table>
</template>

<style scoped>
@media screen {
  #print {
    display: none;
  }
  .right {
    width: 100px;
  }
}

@media print {
}
</style>
<script>
import router from '@/router';
import '@/assets/cupom.css';
import toast from 'bootstrap/js/src/toast';

export default {
  name: 'SalePrint',

  created() {
    this.formData.id = router.currentRoute.value.params.id;
    this.dateHour = this.getDateHours();
    this.loadPrint();
  },

  data() {
    return {
      formData: {
        id: '',
        balanceMoney: '',
        balancePix: '',
        balanceCredit: '',
        balanceDebit: '',
        openDateHour: '',
        code: '',
        nameUser: '',
        total: '',
        boxTotal: ''
      },

      dateHour: ''
    };
  },

  methods: {
    loadPrint() {
      this.$store
        .dispatch('getSalePrint', this.formData.id)
        .then(r => {
          this.formData = r;
        })
        .catch(() => {
          toast.error('Erro desconhecido!', { timeout: 1200 });
        })
        .finally(() => {
          window.print();
        });
    },

    getDateHours() {
      let d = new Date();
      let dia = d.getDate().toString().padStart(2, '0');
      let mes = (d.getMonth() + 1).toString().padStart(2, '0');
      let ano = d.getFullYear();
      let h = d.getHours();
      let m = d.getMinutes();
      let s = d.getSeconds();

      if (h <= 9) h = '0' + h;

      if (m <= 9) m = '0' + m;

      if (s <= 9) s = '0' + s;

      return dia + '/' + mes + '/' + ano + ' - ' + h + ':' + m + ':' + s;
    }
  },

  computed: {
    prodcut() {
      return this.$store.state.order.items.product;
    },

    payment() {
      return this.$store.state.order.items.payment;
    }
  }
};
</script>
