<template>
  <div class="modal-w d-grid position-fixed h-100 w-100 start-0 top-0" v-if="openModalPayment">
    <add-payment v-if="openModalPayment" @close="closeModalPayment"></add-payment>
  </div>

  <form>
    <row-component>
      <col-component class-col="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12 mt-4">
        <button-submit @click="openModalPayment = true" icon="plus" name="Adicionar" />
      </col-component>
    </row-component>
  </form>

  <div class="bg-body-secondary ">
    <div class="bg-white">
      <div  class="__table">
      <table class="table table-striped">
        <thead>
          <tr>
            <th scope="col" style="width: 20%">Item</th>
            <th scope="col" style="width: 35%">Tipo de pagamento</th>
            <th scope="col" style="width: 35%">Valor</th>
            <th scope="col" style="width: 10%">Remover</th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="(data, index) in payment" :key="index">
            <td data-label="Item" >{{ index + 1 }}</td>

            <td data-label="Tipo de pagamento" v-if="data.type == 1"><i class="money bill alternate icon"></i> Dinherio</td>
            <td data-label="Tipo de pagamento" v-if="data.type == 2"><i class="dollar sign icon"></i> PIX</td>
            <td data-label="Tipo de pagamento" v-if="data.type == 3"><i class="credit card icon"></i> Cartão de Crédito</td>
            <td data-label="Tipo de pagamento" v-if="data.type == 4"><i class="credit card icon"></i> Cartão de Débito</td>
            <td data-label="Valor Total">R$ {{ data.price }}</td>
            <td data-label="Remover">
              <button-delete @click="deletePayment(data)" />
            </td>
          </tr>
        </tbody>
      </table>
      </div>
    </div>
  </div>
</template>

<style scoped>
.scrolAuto {
  overflow-y: scroll;
  height: calc(100vh - 340px);
}
.card {
  border: none;
  background: transparent;
}
</style>
<script>
import "@/assets/table.css"
import ColComponent from '@/components/row/ColComponent.vue';
import RowComponent from '@/components/row/RowComponent.vue';
import ButtonSubmit from '@/components/buttons/ButtonSubmit.vue';
import ButtonDelete from '@/components/buttons/ButtonDelete.vue';
import AddPayment from '@/components/modals/AddPayment.vue';
import swal from 'sweetalert';
import { useToast } from 'vue-toastification';
const toast = useToast();

export default {
  name: 'order-payment',
  components: {
    AddPayment,

    ButtonDelete,
    ButtonSubmit,
    RowComponent,
    ColComponent
  },

  props: {
    idSale: {
      required: true
    }
  },


  data() {
    return {
      openModalPayment: false
    };
  },

  methods: {
    closeModalPayment() {
      this.openModalPayment = false;
    },

    deletePayment(data) {
      let typePayment = '';
      if (data.type == 1) typePayment = 'Dinheiro';
      if (data.type == 2) typePayment = 'PIX';
      if (data.type == 3) typePayment = 'Cartão de Crédito';
      if (data.type == 4) typePayment = 'Cartão de Débito';
      swal({
        title: 'Atenção!',
        text: `Deseja realmente remover pagamento  em ${typePayment} no valor de R$ ${data.price}  ?`,
        closeOnClickOutside: false,
        buttons: {
          cancel: {
            text: 'Não',
            visible: true,
            closeModal: true
          },
          confirm: 'Sim'
        }
      }).then(result => {
        if (result) {
          this.$store.dispatch('destroyPayment', data.id);
          toast.success('Pagamento removido com sucesso!', { timeout: 2000 });
        }
      });
    }
  },

  computed: {
    payment() {
      return this.$store.state.order.items.payment;
    }
  }
};
</script>
